<template>
  <div class="container my-5 p-5">
    <div class="row">
      <div class="col-md-6">
        <!-- Replace the src attribute with the actual image URL -->
        <img :src="require('@/assets/img/doen_img.png')" alt="Schalk Burger" class="img-fluid" />
      </div>
      <div class="col-md-6" id="wieonsis">
        <h1 class="text-brand-primary">{{ $t('wat_ons_doen.title') }}</h1>
        <p class="text-brand-primary">{{ $t('wat_ons_doen.description') }}</p>
      </div>
    </div>
    <div class="text-center my-4 p-3">
      <h2 class="text-brand-primary">{{ $t('wat_ons_doen.journey_together') }}</h2>
      <h5 class="text-brand-primary">
        {{ $t('wat_ons_doen.proven_record') }}
      </h5>
    </div>
    <div class="row">
      <div class="col-md-4 p-3" v-for="(card, index) in cards" :key="index">
        <div class="card text-center h-100 bg-brand-primary rounded-3">
          <div class="card-body">
            <img :src="card.icon" class="p-5">
           
            <p class="card-text text-white m-3">{{ $t(card.description) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-brand-primary text-white text-center p-5">
    <p class="m-5">
      {{ $t('wat_ons_doen.future_generations') }}
    </p>
    <h3>
      {{ $t('wat_ons_doen.change_activation') }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "WatOnsDoenAfr",
  data() {
    return {
      cards: [
        {
          icon: require('@/assets/img/doen_icon1.svg'), 
          title: "wat_ons_doen.card1_title",
          description: "wat_ons_doen.card1_description",
        },
        {
          icon: require('@/assets/img/doen_icon2.svg'),
          title: "wat_ons_doen.card2_title",
          description: "wat_ons_doen.card2_description",
        },
        {
          icon: require('@/assets/img/doen_icon3.svg'), 
          title: "wat_ons_doen.card3_title",
          description: "wat_ons_doen.card3_description",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>




