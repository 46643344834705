<template>
  <section class="pt-5">
    <div class="container text-brand-primary p-3">
      <div class="row">
        <div class="col-md-6">
          <img :src="require('@/assets/img/kern_img.png')" class="img-fluid w-full">
        </div>
        <div class="col-md-6" id="onskernwaardes">
          <h1 class="p-3">{{ $t('ons_waardes.title') }}</h1>
          <div>
            <div v-for="(item, index) in waardes" :key="index" class="kernwaarde-item d-flex flex-row">
              <img :src="item.icon" class="p-3">
              <div class="p-4">
                <h3>{{ item.title }}</h3>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OnsKernwaardesAfr',
  computed: {
    waardes() {
      return [
        {
          icon: require('@/assets/img/kern_1.svg'),
          title: this.$t('ons_waardes.values.godvrees.title'),
          description: this.$t('ons_waardes.values.godvrees.description'),
        },
        {
          icon: require('@/assets/img/kern_2.svg'),
          title: this.$t('ons_waardes.values.integriteit.title'),
          description: this.$t('ons_waardes.values.integriteit.description'),
        },
        {
          icon: require('@/assets/img/kern_3.svg'),
          title: this.$t('ons_waardes.values.respek.title'),
          description: this.$t('ons_waardes.values.respek.description'),
        },
        {
          icon: require('@/assets/img/kern_4.svg'),
          title: this.$t('ons_waardes.values.kultuur.title'),
          description: this.$t('ons_waardes.values.kultuur.description'),
        },
      ];
    },
  },
};
</script>
