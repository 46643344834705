<template>
  <div id="HeroCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" :class="{ active: index === 0 }" v-for="(slide, index) in slides" :key="index">
        <div class="overlay"></div>
        <div class="carousel-image" :style="{ backgroundImage: `url(${slide.image})` }"></div>
        <div class="carousel-caption">
          <h1 class="fw-bold display-1 text-brand-primary text-center">{{ $t(slide.title) }}</h1>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#HeroCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#HeroCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "HeroCarousel",
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/MYNBOU.jpg"),
          title: "slides.slide1_title"
        },
        {
          image: require("@/assets/img/LANDBOU.jpg"),
          title: "slides.slide2_title"
        },
        {
          image: require("@/assets/img/FINANSIEEL.jpg"),
          title: "slides.slide3_title"
        },
        {
          image: require("@/assets/img/EIENDOM.jpg"),
          title: "slides.slide4_title"
        },
        {
          image: require("@/assets/img/INDUSTRIEEL.jpg"),
          title: "slides.slide5_title"
        },
      ],
    };
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); /* White color with opacity */
}
.carousel-image {
  width: 100%;
  height: 60vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
}

.carousel-caption {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carousel-caption h1 {
  font-size: 12vh;
}

@media (max-width: 768px) {
  .carousel-caption h1 {
    font-size: 5vh;
  }
}
</style>
