<template>
    <div class="language-selector m-3">
        <label class="p-1">Language:</label>
      <select v-model="selectedLanguage" @change="changeLanguage">
        <option v-for="language in supportedLanguages" :key="language" :value="language">{{ language }}</option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        supportedLanguages: ['Afrikaans', 'English'], // Replace with the supported languages in your application
        selectedLanguage: this.$i18n.locale,
      };
    },
    methods: {
      changeLanguage() {
        this.$i18n.locale = this.selectedLanguage;
      },
    },
  };
  </script>
  
  <style scoped>
.language-selector {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.language-selector select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.language-selector select:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.language-selector option {
  background-color: #fff;
  color: #333;
}

</style>