<template>
  <div id="app">
    <LanguageSelector />
    <HeaderAfr />
    <HeroCarouselAfr />
    <WatOnsDoenAfr />
    <OntmoetSchalkAfr />
    <OntmoetDieSpanAfr />
    <HoeWerkOnsAfr />
    <OnsKernwaardesAfr />
    <KontakOnsAfr />
    <FooterAfr />

    <!-- Add other components here -->
  </div>
</template>

<script>
import LanguageSelector from './components/LanguageSelector.vue';
import HeaderAfr from "./components/HeaderAfr.vue";
import HeroCarouselAfr from "./components/HeroCarouselAfr.vue";
import WatOnsDoenAfr from "./components/WatOnsDoenAfr.vue";
import OntmoetSchalkAfr from "./components/OntmoetSchalkAfr.vue";
import OntmoetDieSpanAfr from "./components/OntmoetDieSpanAfr.vue";
import HoeWerkOnsAfr from "./components/HoeWerkOnsAfr.vue";
import OnsKernwaardesAfr from './components/OnsKernwaardesAfr.vue';
import KontakOnsAfr from './components/KontakOnsAfr.vue';
import FooterAfr from './components/FooterAfr.vue';

export default {
  name: "App",
  components: {
    LanguageSelector,
    HeaderAfr,
    HeroCarouselAfr,
    WatOnsDoenAfr,
    OntmoetSchalkAfr,
    OntmoetDieSpanAfr,
    HoeWerkOnsAfr,
    OnsKernwaardesAfr,
    KontakOnsAfr,
    FooterAfr,
  },
};
</script>

<style>

</style>







