<template>
  <div class="p-3 hoe-werk-ons text-brand-primary text-center" id="hoewerkons">
    <h1>{{ $t('hoe_werk_ons.title') }}</h1>
    <p>
      {{ $t('hoe_werk_ons.description') }}
    </p>
    <div class="row justify-content-md-center pt-5">
      <div class="col-lg-4 p-3" v-for="(item, index) in items" :key="index">
        <div class="card h-100 ">
          <div class="card-body">
            <img :src="item.icon" alt="Icon" class="icon m-3"/>
            <h3>{{ $t(item.title) }}</h3>
            <p>{{ $t(item.description) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HoeWerkOnsAfr",
  data() {
    return {
      items: [
        {
          icon: require('@/assets/img/werk_myn.svg'),
          title:"hoe_werk_ons.sections.mynbou.title",
          description: "hoe_werk_ons.sections.mynbou.description",
        },
        {
          icon:  require('@/assets/img/werk_land.svg'),
          title:"hoe_werk_ons.sections.landbou.title",
          description: "hoe_werk_ons.sections.landbou.description",
        },
        {
          icon:  require('@/assets/img/werk_fin.svg'),
          title:"hoe_werk_ons.sections.finansieel.title",
          description: "hoe_werk_ons.sections.finansieel.description",
        },
        {
          icon:  require('@/assets/img/werk_prop.svg'),
          title:"hoe_werk_ons.sections.eiendom.title",
          description: "hoe_werk_ons.sections.eiendom.description",
        },
        {
          icon:  require('@/assets/img/werk_industry.svg'),
          title:"hoe_werk_ons.sections.kommersieel_industrieel.title",
          description: "hoe_werk_ons.sections.kommersieel_industrieel.description",
        },
      ],
    };
  },
};
</script>

<style scoped>
.hoe-werk-ons {
  background-color: white;
}

.icon {
  width: 50px;
  height: 50px;
}
</style>
