<template>

<head>
  <!-- Other meta tags and title -->

  <!-- Meta description -->
  <meta name="description" content="Welcome to Schalk Burger South Africa - Building Businesses with Integrity and Innovation">

  <!-- Keywords meta tag -->
  <meta name="keywords" content="Schalk Burger, South Africa, Building Businesses, Integrity, Innovation, Mining, Agriculture, Financial, Property, Commercial, Industrial">


  <!-- Open Graph (OG) tags for social media sharing -->
  <meta property="og:title" content="Schalk Burger South Africa - Building Businesses with Integrity and Innovation">
  <meta property="og:description" content="Welcome to our website. Explore how we build businesses with integrity and innovation across diverse industries.">
  <meta property="og:image" content="URL to an image representing your website">
  <meta property="og:url" content="URL of your website">

  <!-- Twitter card tags for Twitter sharing -->
  <meta name="twitter:card" content="summary_large_image">
  <meta name="twitter:title" content="Schalk Burger South Africa - Building Businesses with Integrity and Innovation">
  <meta name="twitter:description" content="Welcome to our website. Explore how we build businesses with integrity and innovation across diverse industries.">
  <meta name="twitter:image" content="URL to an image representing your website">

  <!-- Canonical URL to specify the preferred version of the page -->
  <link rel="canonical" href="URL of this page">

  <!-- Robots meta tag to control indexing and following -->
  <meta name="robots" content="index, follow">

  <!-- Viewport for responsive design -->
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <!-- Other meta tags or custom tags you might need -->


</head>



  <nav class="navbar navbar-expand-lg navbar-light bg-white d-flex">
    <div class="container-fluid ">
      <a class="navbar-brand" href="#">
        <img :src="require('@/assets/img/schalk_logo.png')" alt="Schalk Burger Logo" class="m-3" height="120" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav text-brand-primary mx-auto">
          <li class="nav-item">
            <a href="#" v-scroll-to="'#wieonsis'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.wie_ons_is') }}</a>
          </li>
          <li class="nav-item">
            <a href="#" v-scroll-to="'#ontmoetschalk'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.ontmoet_vir_schalk') }}</a>
          </li>
          <li class="nav-item">
            <a href="#" v-scroll-to="'#ontmoetdiespan'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.ontmoet_die_span') }}</a>
          </li>
          <li class="nav-item">
            <a href="#" v-scroll-to="'#hoewerkons'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.hoe_ons_werk') }}</a>
          </li>
          <li class="nav-item">
            <a href="#" v-scroll-to="'#onskernwaardes'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.ons_kernwaardes') }}</a>
          </li>
          <li class="nav-item">
            <a href="#" v-scroll-to="'#kontakons'" class="nav-link text-brand-primary fw-bold">{{ $t('menu.kontak_ons') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
  
<script>
export default {
  name: "HeaderAfr",
};
</script>
  
<style scoped></style>
  